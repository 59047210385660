import { RouteProps } from 'react-router-dom';
import { headerHeight, screenWidthLarge, screenWidthSmall, spaceL, spaceM, spaceS } from 'styles/variables';
import Header from './Header';
import styled from 'styled-components';

const Container = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: ${headerHeight} 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        'header'
        'main'
        'footer';
`;
const ContentContainer = styled.main`
    grid-area: main;
    padding: ${spaceL} ${spaceM} 6.25rem ${spaceM};
    margin: 0 auto;
    width: 100%;
    max-width: ${screenWidthLarge};
    container-type: inline-size;
    container-name: main-container;

    @media screen and (max-width: ${screenWidthSmall}) {
        padding: ${spaceM} ${spaceS} 6.25rem ${spaceS};
    }
`;

function PageLayout({ children }: RouteProps): JSX.Element {
    return (
        <Container data-testid="pageLayoutContainer">
            <Header />
            <ContentContainer data-testid="main">{children}</ContentContainer>
        </Container>
    );
}

export default PageLayout;
