import { BuType, UseBUContentResult } from 'types/businessUnit';
import { InteractionStatus } from '@azure/msal-browser';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { getCountryFromUrl } from 'utils/url';
import { useAppInsights } from 'contexts/AppInsightsContext';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const emptyBUContent: BuType = {
    privacyPolicyLink: '',
    termsOfSalesLink: '',
    availableLanguages: [],
};

const useBUContent = (): UseBUContentResult => {
    const { appInsights } = useAppInsights();
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const bu = getCountryFromUrl();
    const buFile = `/bu_${bu}.json`;

    const fetcher = async (): Promise<BuType | void> => {
        const result = await axios
            .get<BuType>(buFile)
            .then((resp) => resp.data)
            .catch((err) => {
                if (appInsights) {
                    appInsights.trackException({
                        exception: err,
                        severityLevel: 3,
                    });
                }
            });

        return result;
    };

    const { data: buContent } = useQuery<BuType | void>({
        queryKey: [buFile],
        queryFn: fetcher,
        ...REACT_QUERY_ONE_CALL_OPTIONS,
        enabled: isAuthenticated && inProgress === InteractionStatus.None,
    });

    return {
        buContent: buContent ?? emptyBUContent,
    };
};

export default useBUContent;
