export enum ETrackingEvent {
    SUBMIT_ADDRESS = 'driver_submitAddress',
    CHANGE_LANGUAGE = 'driver_changeLanguage',
    SUBMIT_SKANSKA_FORM_ORDER_CHARGE_KEY_PRIVATE_CAR = 'userform_skanska_orderChargeKeyPrivateCar',
    SUBMIT_SKANSKA_FORM_ORDER_CHARGE_KEY_LEASING_CAR = 'userform_skanska_orderChargeKeyLeasingCar',
    SUBMIT_SKANSKA_FORM_ORDER_CHARGE_KEY_SKANSKA_CAR = 'userform_skanska_orderChargeKeySkanskaCar',
    SUBMIT_SKANSKA_FORM_ORDER_CHARGE_KEY_UTILITY_CAR = 'userform_skanska_orderChargeKeyUtilityCar',
    SUBMIT_SKANSKA_FORM_ORDER_WALLBOX = 'userform_skanska_orderWallbox',
    SUBMIT_FORM_CANCEL_SUBSCRIPTION = 'userform_cancelSubscription',
    SUBMIT_FORM_INSTALLATION = 'userform_installation',
    SUBMIT_FORM_CHARGING_PARTNER = 'userform_chargingPartner',
    SUBMIT_FORM_REPORT_ERROR_GARO = 'userform_reportErrorGaro',
    SUBMIT_FORM_REPORT_ERROR_KEBA = 'userform_reportErrorKeba',
    SUBMIT_HELSINGBORGSHEM_FORM_PRIVATE_CHARGING = 'userform_helsingborgshem_privateCharging',
    SUBMIT_FORM_REGISTER_MER_HUB = 'userform_registerMerHub',
    SUBMIT_FORM_REIMBURSEMENT = 'userform_reimbursement',
    SUBMIT_FORM_ORDER_SUBSCRIPTION = 'userform_orderSubscription',
}
