export enum PATH {
    SUBSCRIPTIONS_DELIVERY_ADDRESS = '/subscriptions/:subscriptionId/delivery-address',

    WILDCARD = '/*',

    ORDER_CHARGE_KEY_LEASING_CAR = '/form/skanska/chargeKeyForLeasingCar',
    ORDER_CHARGE_KEY_SKANSKA_CAR = '/form/skanska/chargeKeyForSkanskaCar',
    ORDER_CHARGE_KEY_PRIVATE_CAR = '/form/skanska/chargeKeyForPrivateCar',
    ORDER_CHARGE_KEY_UTILITY_VEHICLE = '/form/skanska/chargeKeyForUtilityVehicle',
    ORDER_WALLBOX = '/form/skanska/orderWallbox',

    CANCEL_SUBSCRIPTION = '/form/cancelSubscription',
    PRIVATE_CHARGING_HELSINGBORGSHEM = '/form/helsingborgshem/privateCharging',
    CHARGING_PARTNER = '/form/chargingPartner',
    REPORT_ERROR_GARO = '/form/reportErrorGaro',
    REPORT_KEBA_ERROR = '/form/reportErrorKeba',
    REGISTER_MER_HUB = '/form/registerMerHub',
    INSTALLATION_FORM = '/form/installation',
    REIMBURSEMENT_FORM = '/form/reimbursement',
    ORDER_SUBSCRIPTION = '/form/orderSubscription',
}
